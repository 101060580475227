import React , {useState} from "react";
import styled from "styled-components";
import Scroll from 'react-scroll';

// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/photos/1.jpg";
import AddImage2 from "../../assets/img/photos/2.jpg";
import AddImage3 from "../../assets/img/photos/4.jpg";
import AddImage4 from "../../assets/img/photos/5.jpg";

export default function Services() {
  const [isDataCleansing , setIsDataCleansing] = useState(true);
  const [isDataAnalysis , setIsDataAnalysis] = useState(false);
  const [isTrainingEvents , setIsTrainingEvents] = useState(false);
  const scroller = Scroll.scroller;


  const getDescription = async (to) => {
    console.log("to : " , to);
    if (to === 'data-cleansing'){
      setIsDataCleansing(true);
      setIsDataAnalysis(false);
      setIsTrainingEvents(false);
    }
    if (to === 'data-analysis'){
      setIsDataCleansing(false);
      setIsDataAnalysis(true);
      setIsTrainingEvents(false);
    }
    if(to === 'training-events'){
      setIsDataCleansing(false);
      setIsDataAnalysis(false);
      setIsTrainingEvents(true);
    }
    await scroller.scrollTo("service-desc" , {
      spy : true,
      smooth : true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    })
  }
  return (
    <Wrapper id="services">
      {/*<div className="lightBg" style={{ padding: "50px 0" }}>*/}
      {/*  <div className="container">*/}
      {/*    <ClientSlider />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <div id={"service-desc"}>
              {isDataCleansing ?
                 <AddLeft>
                <h2 className="font40 extraBold">About us</h2>
                <h3 className="font13">
                Established three years ago, our Ontario-based company is dedicated to empowering local small businesses with the latest technology. We specialize in creating custom websites, maintaining robust data analytics, and delivering powerful visualizations. 

What sets us apart is our commitment to our community—we're proudly local, and our follow-up services remain free until you're completely satisfied. Partner with us to stay ahead in the digital age, with the personalized support only a local business can offer.
                </h3>
                {/* <ol style={{listStyle : "inherit"}} className={"font14"}>
                  <li>Data auditing: The first step in data cleansing is to audit the data to identify any errors or inconsistencies. This may involve using software tools to identify missing values, incorrect values, or inconsistencies in data formats.
                  </li>
                  <li>Data profiling: Data profiling involves analyzing the data to identify patterns, relationships, and trends. This can help to identify potential errors or inconsistencies in the data.
                  </li>
                  <li>Data standardization: In this step, data is standardized to ensure that it is consistent across the dataset. This may involve converting data to a common format, such as changing all date values to a specific format.</li>
                  <li>Data enrichment: Data enrichment involves adding additional information to the dataset to enhance its value. This may involve adding missing values or supplementing existing data with additional information.
                  </li>
                  <li>Data de-duplication: This step involves identifying and removing duplicate data from the dataset. This helps to ensure that the analysis is based on accurate and unique data.
                  </li>
                  <li>Data validation: Finally, the data is validated to ensure that it meets the requirements of the analysis. This may involve comparing the data to external sources or performing additional checks to ensure its accuracy.
                  </li>
                </ol> */}
                 </AddLeft>
                  : null }
                {/*<ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>*/}
                {/*  <div style={{ width: "190px" }}>*/}
                {/*    <FullButton title="Get Started" action={() => alert("clicked")} />*/}
                {/*  </div>*/}
                {/*  <div style={{ width: "190px", marginLeft: "15px" }}>*/}
                {/*    <FullButton title="Contact Us" action={() => alert("clicked")} border />*/}
                {/*  </div>*/}
                {/*</ButtonsRow>*/}

              {isDataAnalysis ?
                  <AddLeft  >
                {/*<h4 className="font15 semiBold">Data Cleansing</h4>*/}
                <h2 className="font40 extraBold">Data Analysis</h2>
                <h3 className="font15">
                  Data analysis is the process of examining, cleaning, transforming, and modeling data in order to extract meaningful insights and make informed decisions. It involves using various techniques and tools to explore and summarize large datasets, identify patterns and trends, and draw conclusions about the information contained within the data.
                 <br/> The process of data analysis typically involves several stages:

                </h3>
                <ol style={{listStyle : "inherit"}} className={"font14"}>
                  <li>Data collection: This involves gathering relevant data from various sources and storing it in a format that can be easily analyzed.
                  </li>
                  <li>Data cleaning: In this stage, the data is inspected for any errors, inconsistencies, or missing values, and these issues are addressed to ensure that the data is accurate and reliable.
                  </li>
                  <li>
                    Data exploration: Here, statistical and visual methods are used to identify patterns and trends in the data, and to gain a better understanding of the data's structure and characteristics.
                  </li>
                  <li>Data modeling: In this stage, statistical models and algorithms are applied to the data to make predictions and identify relationships between variables.
                  </li>
                  <li>Data interpretation: Finally, the results of the analysis are interpreted and used to inform decision-making and generate insights.</li>
                </ol>
                    </AddLeft>: null}
                {/*<ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>*/}
                {/*  <div style={{ width: "190px" }}>*/}
                {/*    <FullButton title="Get Started" action={() => alert("clicked")} />*/}
                {/*  </div>*/}
                {/*  <div style={{ width: "190px", marginLeft: "15px" }}>*/}
                {/*    <FullButton title="Contact Us" action={() => alert("clicked")} border />*/}
                {/*  </div>*/}
                {/*</ButtonsRow>*/}
              {isTrainingEvents ?
                  <AddLeft  >
                <h2 className="font40 extraBold">Training Events</h2>
                <h3 className="font15">
                  Can include one or more of the following Modules:
                  <ul>
                    <li>Tableau</li>
                    <li>MS Power BI</li>
                    <li>MS Excel</li>
                  </ul>
                  <br/>
                  These events can be altered to meet your business needs. Normally they are designed to provide participants with the knowledge and skills to use each software for data visualization and analysis. The training may
                  <br/>
                  The training may cover a variety of topics, including:
                </h3>
                <ol style={{listStyle : "inherit"}} className={"font14"}>
                 <li>Introduction to each software: Participants will learn about the features and capabilities and how to navigate the software
                 </li>
                  <li>Data modeling: This topic covers how to manipulate data using various tools such as DAX expressions
                  </li>
                  <li>Data visualization: Participants will learn how to create compelling visualizations and dashboards using visualization tools
                  </li>
                  <li>Power Query: This topic covers how to use Power Query to extract, transform, and load data from various sources into Power BI
                  </li>
                  <li>Power BI Service: This topic covers how to share and collaborate on Power BI reports and dashboards using the Power BI service.
                  </li>
                  <li>Advanced topics: Advanced topics may include topics such as custom visuals, machine learning, and advanced DAX functions.
                  </li>
                </ol>
                  </AddLeft> :  null }
                {/*<ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>*/}
                {/*  <div style={{ width: "190px" }}>*/}
                {/*    <FullButton title="Get Started" action={() => alert("clicked")} />*/}
                {/*  </div>*/}
                {/*  <div style={{ width: "190px", marginLeft: "15px" }}>*/}
                {/*    <FullButton title="Contact Us" action={() => alert("clicked")} border />*/}
                {/*  </div>*/}
                {/*</ButtonsRow>*/}
              </div>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Data Cleansing"
                subtitle="Data cleansing is a critical step in the data analysis process, as it helps to ensure that the results of the analysis are accurate and reliable. By removing errors and inconsistencies in the data, data cleansing helps to improve the quality of the analysis and the decisions that are based on it"
                to={"data-cleansing"}
                getDescription={getDescription}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Data Analysis"
                subtitle="Data analysis is the process of examining, cleaning, transforming, and modeling data in order to extract meaningful insights and make informed decisions. It involves using various techniques and tools to explore and summarize large datasets, identify patterns and trends, and draw conclusions about the information contained within the data."
                to={"data-analysis"}
                getDescription={getDescription}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Training Events"
                subtitle="These events can be altered to meet your business needs. Normally they are designed to provide participants with the knowledge and skills to use each software for data visualization and analysis. (Tableau, MS Power BI, MS Excel)"
                to={"training-events"}
                getDescription={getDescription}
              />
            </ServiceBoxWrapper>
            </ServiceBoxRow>
            <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Custom Software Development"
                subtitle="We develop tailor-made software solutions that align with your business goals and requirements."
                to={"custom-software-development"}
                getDescription={getDescription}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Mobile App Development"
                subtitle="Our team creates high-performance mobile applications for iOS, Android, and cross-platform solutions using Flutter and React Native."
                to={"mobile-app-development"}
                getDescription={getDescription}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Web Development"
                subtitle="We build responsive and dynamic websites and web applications using the latest technologies like React.js, Node.js, Python and modern frameworks."
                to={"web-development"}
                getDescription={getDescription}
              />
            </ServiceBoxWrapper>
            </ServiceBoxRow>
            <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Cloud Solutions"
                subtitle="We offer cloud consulting, migration, and management services to help you leverage the power of cloud computing like GCP, AWS, etc."
                to={"training-events"}
                getDescription={getDescription}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="UI/UX and Graphic Design"
                subtitle="Our design team creates intuitive and engaging user interfaces that enhance user experience and develop visually compelling graphics to complement your brand identity."
                to={"ux-ui-events"}
                getDescription={getDescription}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Consulting and Strategy"
                subtitle="We offer strategic consulting to help you make informed technology decisions that drive business success."
                to={"consulting-and-strategy"}
                getDescription={getDescription}
              />
            </ServiceBoxWrapper>
            {/*<ServiceBoxWrapper>*/}
            {/*  <ServiceBox icon="printer" title="Print" subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor." />*/}
            {/*</ServiceBoxWrapper>*/}
          </ServiceBoxRow>
        </div>
     
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 33%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: 70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: 40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;