import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
// import Logo from "../../assets/img/light-logo.png";
import Logo from "../../assets/img/dark-logo.png";
import fb from "../../assets/img/facebook.png";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <img src={Logo} width={70}/>
              {/*<h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>*/}
              {/*  VizPulse*/}
              {/*</h1>*/}
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className="purpleColor font13">VizPulse</span> All Right Reserved
            </StyleP>
            
           
            <a className="whiteColor animate pointer font13 flexRight" style={{ padding: "10px 15px" }} target={"_blank"} href={"https://www.facebook.com/profile.php?id=100088626643482&mibextid=LQQJ4d"}>
            <span className="whiteColor animate font13 flexRight" style={{display: "block"}}>
            Follow us on
            </span>
              <img src={fb} width={30} />
            </a>
            {/*<Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>*/}
            {/*  Back to top*/}
            {/*</Link>*/}
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;